import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usoutsidehh from '../assets/images/usoutsidehh.jpg';
import Waitresses from '../assets/images/waitresses.jpg';
import Christyatstove from '../assets/images/christyatstove.jpg';
import Jukebox from '../assets/images/jukebox.jpg';
import Uswithsarah from '../assets/images/uswithsarah.jpg';
import Hhoutside from '../assets/images/hhoutside.jpg';
import Hhphone from '../assets/images/hhphone.jpg';
import Hhinterior from '../assets/images/hhinterior.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Oxford: 1-19-01" />
      <div>
        <TripHeader>
          <h2>Oxford</h2>
          <p>1-19-01</p>
          <img
            src={Usoutsidehh}
            alt="Bailey, Michael Wayne, Josh, & Keli outside the Huddle House"
          />
          <ImageCaption>
            Bailey, Michael Wayne, Josh, & Keli outside the Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Waitresses} alt="Christy, Joanna, and Sarah" />
            <ImageCaption>Christy, Joanna, and Sarah</ImageCaption>
          </div>
          <div>
            <img src={Christyatstove} alt="Christy at the Stove" />
            <ImageCaption>Christy at the Stove</ImageCaption>
          </div>
          <div>
            <img src={Jukebox} alt="Huddle House Jukebox" />
            <ImageCaption>Huddle House Jukebox</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          Our last adventure to the Huddle House was a short, but important
          trip. We only had time to drop in and say hey to the waitresses on
          shift at the time and take a few pictures. It was important because we
          took our first pictures of the Huddle House! Sarah, Christy, and
          Joanna, the waitresses on duty, were very excited to see us and glad
          we finally got the website underway. They were as nice as ever and,
          very supportive in our efforts to get good photos for the new website.
          Bailey, Josh, Michael Wayne, and a guest Keli Snow went in and got
          pictures while Patrick and Pooh stayed in the truck. It was a fun trip
          and also a most important one. I can't wait until we go back!
        </p>
        <ImageGrid>
          <div>
            <img src={Hhinterior} alt="Inside" />
            <ImageCaption>Inside</ImageCaption>
          </div>
          <div>
            <img src={Uswithsarah} alt="Us with Sarah" />
            <ImageCaption>Us with Sarah</ImageCaption>
          </div>
          <div>
            <img src={Hhoutside} alt="Outside" />
            <ImageCaption>Outside</ImageCaption>
          </div>
          <div>
            <img src={Hhphone} alt="Phone" />
            <ImageCaption>Phone</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
